import React from "react"
import Layout from "../../components/Layout"
import PostArchive from "../../components/PostArchive"
import Pagination from "../../components/Pagination"
import SEO from '../../components/SEO';


const BlogArchive = props => {
  const {
    pageContext: { nodes, pageNumber, hasNextPage, itemsPerPage, allPosts },
  } = props
  return (
    <Layout>
      <SEO/>
      <section className="block-section layout-sidebar">
      <div className="row-full">
      <div className="container">
      <div className="row lsco-block py-5">

      <div id="primary" className="content-area">
        <main id="main" className="site-main">
          <div className="post-archive">
            <div className="archive-main row">
            {nodes && nodes.map(post => {
                return (
                  <div key={post.id} className="columns col-sm-6">
                    <PostArchive post={post} />
                  </div>
                  );
              })}
            </div>{/* End of .archive-list */}
          </div>{/* End of .post-archive */}
        </main>{/* End of #main */}
      </div>{/* End of #primary */}

      </div>{/* End of .lsco-block */}
      </div>{/* End of .container */}
      </div>{/* End of .row-full */}
      </section>
      <Pagination
        pageNumber={pageNumber}
        hasNextPage={hasNextPage}
        allPosts={allPosts}
        itemsPerPage={itemsPerPage}
      />
    </Layout>
  )
}

export default BlogArchive
